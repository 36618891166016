import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import linkedin from "../../Assets/linkedin.png";
import github from "../../Assets/github.png";
import emailIcon from "../../Assets/email.png";
import tableau from "../../Assets/business.png";
import { motion } from "framer-motion";
const Contact = ({ Element }) => {
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    const myForm = event.target;
    const formData = new FormData(myForm);
    setSubmitBtnDisable(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        event.target.reset();
        toast({
          position: "top-right",
          status: "warning",
          title: `Your inquiry has been noted! I'll be in touch shortly.`,
          isClosable: false,
        });
        setSubmitBtnDisable(false);
      })
      .catch((error) => alert(error));
  };

  const style1 = {
    WebkitTextStroke: "1px #dee2e6",
  };

  return (
    <>
      <Element name="scroll-to-contact" className="pt-10 md:pt-40">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.75, duration: 5 }}
          className=" container mb-12 md:mb-24"
        >
          <div className="relative flex items-center justify-center mb-10 md:mb-20">
            <h1
              className="text-white dark:text-black  font-poppins font-base text-5xl md:text-7xl lg:text-8xl xl:text-9xl uppercase"
              style={style1}
            >
              Contact
            </h1>
            <h4 className="text-orange-500 font-roboto text-lg md:text-2xl lg:text-3xl xl:text-4xl font-bold absolute uppercase">
              Let's talk
            </h4>
          </div>
          <form
            name="contactMeForm"
            method="POST"
            onSubmit={handleSubmit}
            action="/contact"
          >
            <input type="hidden" name="form-name" value="contactMeForm" />
            <div hidden>
              <input name="bot-field" />
            </div>
            <div className="space-y-6 container w-full md:w-3/4 xl:w-1/2  px-6 md:px-0">
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                  <input
                    type="text"
                    placeholder="Your Name"
                    required={true}
                    name="userName"
                    maxLength={50}
                    className="w-full rounded-full border-2 border-gray-200 px-6 py-2 md:py-4 autofill:bg-orange-200  focus:outline-none focus:border-orange-500"
                  />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <input
                    type="email"
                    placeholder="Your Email"
                    required={true}
                    name="userEmail"
                    maxLength={50}
                    className="w-full rounded-full border-2 border-gray-200 px-6 py-2 md:py-4 autofill:bg-orange-200  focus:outline-none focus:border-orange-500"
                  />
                </div>
              </div>
              <div className="px-2">
                <input
                  type="text"
                  placeholder="Subject"
                  required={true}
                  name="subject"
                  maxLength={50}
                  className="w-full rounded-full border-2 border-gray-200 px-6 py-2 md:py-4 autofill:bg-orange-200  focus:outline-none focus:border-orange-500"
                />
              </div>
              <div className="px-2">
                <textarea
                  type="text"
                  placeholder="Typing your message here..."
                  required={true}
                  name="message"
                  rows="4"
                  cols="5"
                  maxLength={500}
                  className="w-full rounded-3xl border-2 border-gray-200 px-6 py-2 md:py-4 autofill:bg-orange-200  focus:outline-none focus:border-orange-500"
                />
              </div>
              <div className="flex justify-center items-center">
                <button
                  className="mt-4 text-base md:text-lg font-semibold rounded-full bg-orange-500 text-white px-10 py-2  cursor-pointer disabled:bg-orange-100 disabled:cursor-wait"
                  size="lg"
                  type="submit"
                  disabled={submitBtnDisable}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </motion.div>
        <div className="flex justify-center items-center h-32 md:h-56 bg-orange-300 space-x-10 py-10 md:py-0">
          <a
            href="mailto:yadavprasad8500@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="bg-white p-3 rounded-full"
          >
            <img
              src={emailIcon}
              alt="email"
              className="w-6 md:w-10 h-6 md:h-10"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/prasad-yadav"
            target="_blank"
            rel="noreferrer"
            className="bg-white p-3 rounded-full"
          >
            <img
              src={linkedin}
              alt="linkedin"
              className="w-6 md:w-10 h-6 md:h-10 "
            />
          </a>

          <a
            href="https://github.com/prasad2507"
            target="_blank"
            rel="noreferrer"
            className="bg-white p-3 rounded-full"
          >
            <img
              src={github}
              alt="linkedin"
              className="w-6 md:w-10 h-6 md:h-10 "
            />
          </a>

          <a
            href="https://public.tableau.com/app/profile/prasad2507"
            target="_blank"
            rel="noreferrer"
            className="bg-white p-3 rounded-full"
          >
            <img
              src={tableau}
              alt="tableau"
              className="w-6 md:w-10 h-6 md:h-10 "
            />
          </a>
        </div>
      </Element>
    </>
  );
};

export default Contact;
