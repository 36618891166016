import { skillValue } from "../../utils/skills";
import { motion } from "framer-motion";
const Skills = ({ Element }) => {
  const style1 = {
    WebkitTextStroke: "1px #dee2e6",
  };
  const fadeInAnimationVariants = {
    initial: {
      opacity: 0,
      x: -100,
    },
    animate: (index) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5 * index,
        type: "spring",
      },
    }),
  };
  return (
    <>
      <Element name="scroll-to-skills" className="pt-10 md:pt-40">
        <div className="container">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.75, duration: 5 }}
            className="relative flex items-center justify-center mb-10 md:mb-20"
          >
            <h1
              className="text-white dark:text-black font-poppins font-base text-5xl md:text-7xl lg:text-8xl xl:text-9xl uppercase"
              style={style1}
            >
              Skills
            </h1>
            <h4 className="text-orange-500 font-roboto text-lg md:text-2xl lg:text-3xl xl:text-4xl font-bold absolute uppercase">
              My Skills
            </h4>
          </motion.div>
          <div className="flex flex-wrap">
            {skillValue?.map((item, index) => {
              return (
                <motion.div
                  variants={fadeInAnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                  className="w-full md:w-full lg:w-1/2 xl:w-1/2  mb-4 md:mb-10 px-6"
                  key={item.id}
                >
                  <div className="flex justify-between items-center align-middle mb-2">
                    <p className="font-poppins font-bold text-base md:text-lg text-black dark:text-white">
                      {item.name}
                    </p>
                    <p
                      className={`font-poppins font-bold text-base md:text-lg text-black dark:text-white`}
                    >
                      {item.value}/10
                    </p>
                  </div>
                  <div className="w-full h-4 bg-gray-200 relative rounded-full">
                    <motion.div
                      className={`${item.classValue} h-4 ${item.color} absolute rounded-full revealWidth`}
                    ></motion.div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </Element>
    </>
  );
};

export default Skills;
