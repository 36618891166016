import React from "react";
import prasadImg from "../../Assets/prasad_img2.png";
import resume from "../../Assets/pdf/updated_Resume.pdf";
// import sample from "../../Assets/pdf/sample.pdf";
import prasadVideo from "../../Assets/video/prasad_video1.mp4"
import { FaPlayCircle } from "react-icons/fa";
import Typewriter from "typewriter-effect";
import { Link } from "react-scroll";
import { IoIosArrowDown } from "react-icons/io";
import { motion } from "framer-motion";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
const Home = ({ Element, isScrollDown }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const style1 = {
    WebkitTextStroke: "2px #ffffff",
  };

  const handleOpenModal = () => {
    onOpen();
  };

  return (
    <div>
      <Element name="scroll-to-home">
        <div className="flex justify-center items-center relative h-screen bg-orange-300">
          <div className="container mx-10 md:mx-24 xl:mx-72">
            <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{
                delay: 0.3,
                type: "spring",
                stiffness: 120,
              }}
              className="lg:flex items-center align-middle"
            >
              <div className="flex justify-center mr-0 lg:mr-20 mb-10">
                <img
                  src={prasadImg}
                  className="w-[200px] md:w-[400px] lg:w-[400px] xl:w-[400px] rounded-full shadow-sm"
                  alt="Vk_img"
                />
              </div>
              <div className="text-center lg:text-left">
                <p className="text-white text-2xl md:text-4xl mb-6">I'm</p>
                <h2
                  className="mb-6 font-poppins text-4xl md:text-8xl uppercase text-orange-300"
                  style={style1}
                >
                  Prasad yadav
                </h2>
                <h4 className="text-2xl md:text-4xl font-roboto text-white mb-10">
                  <Typewriter
                    options={{
                      strings: [
                        "Web Developer",
                        "Front End Develeoper",
                        "Veeva Developer",
                        "Power BI Developer",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h4>
                <div className="space-x-8 flex align-middle items-center justify-center lg:justify-start">
                  {/* <a
                    href={resume}
                    download="Prasad's Resume"
                    className="uppercase align-middle px-6 py-3 rounded-full border border-white font-poppins text-white text-lg hover:bg-white hover:text-black"
                  >
                    Download CV
                  </a> */}
                  <button
                    onClick={handleOpenModal}
                    className="font-poppins text-lg text-white "
                  >
                    <FaPlayCircle className="w-10 h-10 zoom-in-zoom-out" />
                  </button>
                  <button
                    onClick={handleOpenModal}
                    className="hidden md:block font-poppins text-xl text-white font-medium"
                  >
                    Play Video
                  </button>
                </div>
              </div>
            </motion.div>
          </div>
          {!isScrollDown && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.75, duration: 5 }}
              className="absolute bottom-1 xl:bottom-10 left-[45%]"
            >
              <Link
                activeClass="text-white dark:text-orange-500"
                className="cursor-pointer text-black dark:text-white"
                to="scroll-to-about"
                spy={true}
                smooth={true}
                duration={500}
              >
                <IoIosArrowDown className="w-10 h-10  text-white animate-bounce " />
              </Link>
            </motion.div>
          )}
        </div>
        <Modal onClose={onClose} isOpen={isOpen} size={"xl"} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <video
                className="mt-10"
                width={"1024px"}
                height="720"
                autoPlay
                preload={true}
                controls
              >
                <source src={prasadVideo} type="video/mp4" />
              </video>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Element>
    </div>
  );
};

export default Home;
