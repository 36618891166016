import React, { useState } from "react";
import { Switch } from "@chakra-ui/react";
import { useTheme } from "next-themes";
// import vkImg from "../../Assets/vk_img.jpg";
// import prasadImg from "../../Assets/prasad_img.jpg";

import { MdClose } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";

const Sidebar = ({ Link, isScrollDown, setIsScrollDown }) => {
  const { theme, setTheme } = useTheme();
  const [isOpenSidbar, setIsOpenSidebar] = useState(false);

  return (
    <>
      {/* large screen */}
      {isScrollDown && (
        <div className="hidden fixed fadeIn dur1600 del200 rounded-full w-full md:max-w-[90%]  xl:max-w-[calc(80%-1rem)] top-4 left-1/2 -translate-x-1/2 mx-auto md:text-sm lg: xl:text-lg font-semibold full bg-orange-500 dark:bg-gray-800 text-white dark:text-orange-500 shadow-[rgba(0,0,15,0.5)_0px_5px_4px_0px]  lg:flex justify-center px-16 py-2 z-50">
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-x-4">
              <div>
                <p className="md:text-base text-lg font-bold">Prasad Yadav</p>
                <p className=" text-xs font-base">Web Developer</p>
              </div>
            </div>
            <div className="p-4 gap-x-8 flex justify-end items-center ">
              <div>
                <Link
                  activeClass="text-black dark:text-white border-b-2 pb-2 "
                  className="cursor-pointer"
                  to="scroll-to-home"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </div>
              <div>
                <Link
                  activeClass="text-black dark:text-white border-b-2 pb-2 "
                  className="cursor-pointer"
                  to="scroll-to-about"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  About
                </Link>
              </div>
              <div>
                <Link
                  activeClass="text-black dark:text-white border-b-2 pb-2"
                  className="cursor-pointer"
                  to="scroll-to-experience"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Experience
                </Link>
              </div>
              <div>
                <Link
                  activeClass="text-black dark:text-white border-b-2 pb-2"
                  className="cursor-pointer"
                  to="scroll-to-skills"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Skills
                </Link>
              </div>
              <div>
                <Link
                  activeClass="text-black dark:text-white border-b-2 pb-2"
                  className="cursor-pointer"
                  to="scroll-to-achivement"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Achivements
                </Link>
              </div>
              <div>
                <Link
                  activeClass="text-black dark:text-white border-b-2 pb-2"
                  className="cursor-pointer"
                  to="scroll-to-contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Let's Talk!
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-between space-x-2">
              <p>Light</p>
              <Switch
                id="email-alerts"
                defaultChecked={theme === "dark" ? true : false}
                colorScheme="orange"
                size={"md"}
                onChange={() => {
                  setTheme(theme === "light" ? "dark" : "light");
                }}
              />
              <p htmlFor="email-alerts">Dark</p>
            </div>
          </div>
        </div>
      )}
      {/* small screen */}
      {isScrollDown && (
        <div className="z-50 relative fadeIn dur1600 del200 realtive lg:hidden ">
          <div className="w-full fixed h-16  bg-white dark:bg-gray-800 text-white dark:text-gray-300  flex justify-between items-center align-middle px-4 shadow-[rgba(0,0,15,0.5)_0px_5px_4px_0px]">
            <div className="flex align-middle items-center">
              {/* <div>
              <img
                src={prasadImg}
                className="w-10 h-10 mr-4 border-2 border-white rounded-full"
                alt="Vk_img"
              />
            </div> */}
              <div>
                <p className="text-base font-bold text-orange-500">
                  Prasad Yadav
                </p>
                <p className="text-sm font- text-black dark:text-white">
                  Web Developer
                </p>
              </div>
            </div>
            <div className="block">
              <button
                className=" block"
                onClick={() => setIsOpenSidebar(!isOpenSidbar)}
              >
                {!isOpenSidbar ? (
                  <RxHamburgerMenu className="w-6 h-10 text-black dark:text-white" />
                ) : (
                  <MdClose className="w-6 h-10 text-black dark:text-white" />
                )}
              </button>
            </div>
          </div>
          {isOpenSidbar && (
            <div className="w-full h-max fixed top-16 pt-2 pb-10 bg-white dark:bg-gray-800 transition-all duration-500 overflow-hidden shadow-[rgba(0,0,15,0.5)_0px_5px_4px_0px]">
              <div className="space-y-4 text-left px-4 text-base font-semibold">
                <div>
                  <Link
                    activeClass="text-orange-500"
                    className="cursor-pointer dark:text-gray-400"
                    to="scroll-to-home"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => setIsOpenSidebar(!isOpenSidbar)}
                  >
                    Home
                  </Link>
                </div>
                <div>
                  <Link
                    activeClass="text-orange-500"
                    className="cursor-pointer  dark:text-gray-400"
                    to="scroll-to-about"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => setIsOpenSidebar(!isOpenSidbar)}
                  >
                    About
                  </Link>
                </div>
                <div>
                  <Link
                    activeClass="text-orange-500"
                    className="cursor-pointer dark:text-gray-400"
                    to="scroll-to-experience"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => setIsOpenSidebar(!isOpenSidbar)}
                  >
                    Experience
                  </Link>
                </div>
                <div>
                  <Link
                    activeClass="text-orange-500 "
                    className="cursor-pointer dark:text-gray-400"
                    to="scroll-to-skills"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => setIsOpenSidebar(!isOpenSidbar)}
                  >
                    Skills
                  </Link>
                </div>
                <div>
                  <Link
                    activeClass="text-orange-500"
                    className="cursor-pointer dark:text-gray-400"
                    to="scroll-to-achivement"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => setIsOpenSidebar(!isOpenSidbar)}
                  >
                    Achivements
                  </Link>
                </div>
                <div>
                  <Link
                    activeClass="text-orange-500"
                    className="cursor-pointer dark:text-gray-400"
                    to="scroll-to-contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => setIsOpenSidebar(!isOpenSidbar)}
                  >
                    Let's Talk!
                  </Link>
                </div>
              </div>
              <div className=" mx-4 ">
                <div className="flex items-center justify-between mt-4 ">
                  <p className="text-base font-semibold dark:text-gray-400">
                    Dark Mode
                  </p>
                  <Switch
                    id="email-alerts"
                    defaultChecked={theme === "dark" ? true : false}
                    colorScheme="orange"
                    size={"md"}
                    onChange={() => {
                      setTheme(theme === "light" ? "dark" : "light");
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {/* {!isOpenSidbar && isScrollDown && (
          <div className="absolute bottom-12 right-6">
            <Link
              className="bg-orange-500 rounded-full p-2 align-middle flex items-center"
              to="scroll-to-home"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => setIsScrollDown(!isScrollDown)}
            >
              <ArrowUpIcon boxSize={6} color={"white"} />
            </Link>
          </div>
        )} */}
        </div>
      )}
    </>
  );
};

export default Sidebar;
