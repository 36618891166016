import React from "react";
import { basicInfoValue } from "../../utils/basicInfo";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
const About = ({ Element }) => {
  const style1 = {
    WebkitTextStroke: "1px #dee2e6",
  };

  return (
    <div>
      <Element name="scroll-to-about" className="pt-20 md:pt-40">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.75, duration: 5 }}
          className=" container"
        >
          <div className="relative flex items-center justify-center mb-10 md:mb-20">
            <h1
              className="text-white dark:text-black font-poppins font-base text-5xl md:text-7xl lg:text-8xl xl:text-9xl uppercase"
              style={style1}
            >
              ABOUT
            </h1>
            <h4 className="text-orange-500 font-roboto text-lg md:text-2xl lg:text-3xl xl:text-4xl font-bold absolute">
              ABOUT ME
            </h4>
          </div>
          <div className="mx-auto w-full md:w-3/4 xl:w-1/2 space-y-4 md:space-y-10 px-6 md:px-0">
            <h4 className="font-bold font-poppins text-lg md:text-xl text-black dark:text-white text-center">
              Web Developer & Veeva Developer
            </h4>
            <p className="mt-2 md:mt-4 text-gray-500 dark:text-gray-400 text-base md:text-lg font-normal text-left">
              An experienced Software Developer in Web development, skilled in
              React, Nuxt.js, Salesforce, Veeva, SQL, Tableau and PowerBI. Professional
              with a Master's degree in Computer Science with a goal to work for
              a progressive organization which provides me opportunities to
              utilize my capability to achieve the organization’s goal and
              enables me to grow as a professional.
            </p>
            <div className="flex flex-wrap">
              {basicInfoValue?.map((item) => {
                return (
                  <div className="w-full md:w-1/2 mb-4 md:mb-6" key={item.id}>
                    <p className="font-normal text-base md:text-lg text-gray-700 dark:text-gray-400">
                      <span className="mr-2 font-semibold text-gray-500 dark:text-gray-300">
                        {item.infoTitle}:
                      </span>
                      {item.infoValue}
                    </p>
                  </div>
                );
              })}
            </div>
            <Link
              className="mt-6 block w-max text-base md:text-lg font-semibold rounded-full bg-orange-500 text-white px-10 py-2  cursor-pointer disabled:bg-orange-100 disabled:cursor-wait"
              to="scroll-to-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Let's talk!
            </Link>
          </div>
        </motion.div>
      </Element>
    </div>
  );
};

export default About;
