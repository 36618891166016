export const skillValue = [
  {
    id: 1,
    name: "React JS",
    value: "9",
    classValue: "w-[90%]",
    color: "bg-blue-500",
    textColor: "text-blue-500",
  },
  {
    id: 2,
    name: "MySQL",
    value: "7",
    classValue: "w-[70%]",
    color: "bg-teal-500",
    textColor: "text-teal-500",
  },
  {
    id: 3,
    name: "Veeva",
    value: "9",
    classValue: "w-[90%]",
    color: "bg-orange-500",
    textColor: "text-orange-500",
  },
  {
    id: 4,
    name: "Salesforce",
    value: "8",
    classValue: "w-[80%]",
    color: "bg-blue-900",
    textColor: "text-blue-900",
  },
  {
    id: 5,
    name: "Tableau",
    value: "7.5",
    classValue: "w-[75%]",
    color: "bg-violet-500",
    textColor: "text-violet-500",
  },
  {
    id: 6,
    name: "Power BI",
    value: "8",
    classValue: "w-[80%]",
    color: "bg-yellow-500",
    textColor: "text-yellow-500",
  },

  // {
  //   id:7,
  //   name: "Firebase",
  //   value:"8.5",
  //   classValue:"w-[85%]",
  //   color:"bg-rose-500",
  //   textColor:"text-rose-500",
  // },
];
