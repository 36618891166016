import "./App.css";
import Home from "../src/components/home/Home";
import Experience from "../src/components/experience/Experience";
import Skills from "../src/components/skills/Skills";
import Achivement from "../src/components/achivements/Achivement";
import Contact from "../src/components/contact/Contact";
import Sidebar from "../src/components/sidebar/Sidebar";
import { Link, Element } from "react-scroll";
import { ChakraProvider } from "@chakra-ui/react";
import { ThemeProvider } from "next-themes";
import { useEffect, useState } from "react";
import About from "./components/about/About";

function App() {
  const [isScrollDown, setIsScrollDown] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 400) {
        setIsScrollDown(true);
      } else {
        setIsScrollDown(false);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [isScrollDown]);

  // useEffect(() => {
  //   setTheme("dark"); //set your theme here after component mounts
  // }, [theme]);

  return (
    <ThemeProvider defaultTheme="system" >
      <ChakraProvider>
        <Sidebar
          Link={Link}
          isScrollDown={isScrollDown}
          setIsScrollDown={setIsScrollDown}
        />
        <Home Element={Element} isScrollDown={isScrollDown} />

        <About Element={Element} />
        <Experience Element={Element} />
        <Skills Element={Element} />
        <Achivement Element={Element} />
        <Contact Element={Element} />
        {/* <Certification Element={Element} /> */}
      </ChakraProvider>
    </ThemeProvider>
  );
}

export default App;
